.thumbnail-media {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que las imágenes/videos mantengan su proporción */
}

blockquote{
  padding: 0;
  margin: 1rem;
  border: 0;
}

.portfolio-item {
  padding: 1rem;
}

.tiktok-embed {
  max-width: 100%;
  height: 50%;
}


@media screen and (max-width: 600px) {
  html{
    width:100%;
  }

  #header{
    width: 100%;

  }

  #features,
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 110% !important;
    padding : 3rem !important;
  }


  #portfolio {
    width: 110%;
    padding : 1rem;
  }

  .intro {
    display: table;
    width: 110%;
    background: url(../public/img/intro-bg.jpg);
    background-color: #e5e5e5;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
  }

  .intro h1 {
    width: 100%;
    font-size: 2.5em;
  }

  .intro .text-justify {
    padding: 1rem;
    text-align: center;
  }

  .thumbnail-container {
    width: 100%; /* Reducimos el ancho del contenedor a la mitad */
    position: relative;
    overflow: hidden;
    margin: 0 auto; /* Centrar el contenedor */
  }
  
  
}
